<template>
<v-row>
  <v-col cols="12">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 150)

                  ]"
                  v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field dense
        label="* Valor"
        prefix="R$"
        :rules="[
            this.$validators.number.required,
            v => this.$validators.number.greaterThanOrEquals(v, 0)
        ]"
        v-model="value.valor" type="number"/>
  </v-col>
  <v-col cols="12" md="6">
  <lookup-dominio label="* Status" :hidden-refresh="true" :hidden-clear="true"
    v-model="value.status"
    :type="lookups.status" 
    :rules="[
      this.$validators.string.required
    ]">
  </lookup-dominio>
</v-col>   
</v-row>
</template>

<script>
import LookupDominio from "../common/LookupDominio";
import { ItemDeDominio } from "../../../api/itemsDominio";

export default {
  name: "EventoForm",
  components: { LookupDominio },
  props: {
    value: {
      type: Object
    }
  },
    data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
      },
    }
  },
}
</script>

<style scoped>

</style>