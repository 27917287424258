var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Valor","prefix":"R$","rules":[
            this.$validators.number.required,
            function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
        ],"type":"number"},model:{value:(_vm.value.valor),callback:function ($$v) {_vm.$set(_vm.value, "valor", $$v)},expression:"value.valor"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Status","hidden-refresh":true,"hidden-clear":true,"type":_vm.lookups.status,"rules":[
      this.$validators.string.required
    ]},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }